import type { TextFieldProps } from '@mui/material/TextField';
import * as MuiTextField from '@mui/material/TextField';
import type { ChangeEvent } from 'react';
import React, { useRef, useState } from 'react';
import type { Control, Path, RegisterOptions } from 'react-hook-form';
import { useController } from 'react-hook-form';

import Typography from '@/contents/General/Typography';

import Stack from './Stack';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
    green: true;
  }
}

type Props = TextFieldProps & {
  control?: Control<any>;
  name?: Path<any>;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  onChange?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void | any;
};

const RFHTextField = (props: Props & { name: string }) => {
  const { name, control, rules, ...textFieldProps } = props;
  const {
    field: { ref, ...rest },
    fieldState,
    formState: { errors },
  } = useController({
    name,
    control,
    rules,
  });
  return (
    <Stack column space={1}>
      <MuiTextField.default
        inputRef={ref}
        {...rest}
        {...textFieldProps}
        error={!!fieldState.error}
      />
      {fieldState.error && (
        <Typography fontSize={14} fontWeight={500} color="#9B4450">
          {fieldState.error.message}
        </Typography>
      )}
    </Stack>
  );
};

const TextField = ({
  value,
  children,
  onChange,
  placeholder,
  inputProps,
  InputProps,
  error,
  helperText,
  disabled,
  type,
  rows,
  multiline,
  sx,
  name,
  control,
  rules,
  required,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const [isError, setIsError] = useState<boolean>(false);

  const changeInputValue = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (inputRef.current && onChange) {
      const { patternMismatch } = inputRef.current.validity;
      if (patternMismatch) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      onChange(e);
    }
  };
  if (!!name && !!control) {
    return (
      <RFHTextField
        name={name}
        control={control}
        rules={rules}
        variant="outlined"
        multiline={multiline}
        rows={rows}
        fullWidth
        placeholder={placeholder}
        inputProps={inputProps}
        InputProps={InputProps}
        error={isError || error}
        helperText={isError || error ? helperText : ''}
        disabled={disabled}
        inputRef={inputRef}
        type={type}
        sx={sx}
      />
    );
  }

  return (
    <MuiTextField.default
      value={value}
      onChange={changeInputValue}
      variant="outlined"
      multiline={multiline}
      rows={rows}
      fullWidth
      placeholder={placeholder}
      inputProps={inputProps}
      InputProps={InputProps}
      error={isError || error}
      helperText={isError || error ? helperText : ''}
      disabled={disabled}
      inputRef={inputRef}
      required={required}
      type={type}
      sx={sx}
    >
      {children}
    </MuiTextField.default>
  );
};

export default TextField;
