export const getBlankName = () => {
  return '名無しさん';
};
export const getBlankImage = () => {
  return '/assets/images/sample/sample_user_icon1.png';
};
export const getBlankUserIcon = () => {
  return '/assets/images/icons/icon_person.svg';
};

export const getBlankBackGroundImage = () => {
  return '/assets/images/common/bg_mypage.png';
};
