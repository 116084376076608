// @ts-ignore
import type { BoxTypeMap } from '@mui/material/Box';
import Box from '@mui/material/Box';
import type { ButtonTypeMap } from '@mui/material/Button';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';

import theme from '@/styles/theme';

type BorderColor =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'white'
  | 'green'
  | 'red'
  | 'line'
  | 'amazon'
  | 'facebook'
  | 'transparent'
  | undefined;
const getBorder = (
  transparent?: boolean,
  noBorder?: boolean,
  borderColor?: BorderColor,
  borderWidth?: number
) => {
  let borderWidthNum = 1;
  let borderColorName = '#e3e9e6';
  // no_borderが設定されていた場合、枠線を消す
  if (noBorder) {
    return 'unset';
  }
  if (transparent) {
    borderColorName = '#c2c8c4';
  }
  // border_colorが設定されていた場合、枠線の色を変更
  if (borderColor) {
    borderColorName = theme.palette[borderColor].main;
  }
  // borderWidthが設定されていた場合、枠線の太さを変更
  if (borderWidth) {
    borderWidthNum = borderWidth;
  }

  return `solid ${borderWidthNum}px ${borderColorName}`;
};

export const Board: any = styled(Box)<{
  transparent?: string;
  no_border?: string;
  // 枠線の色
  border_color?: BorderColor;
  // 枠線の太さ
  border_width?: number;
}>`
  padding: ${theme.spacing(2)};
  border: ${(props) =>
    getBorder(
      props.transparent === 'true',
      props.no_border === 'true',
      props.border_color,
      props.border_width
    )};
  border-radius: 8px;
  background: ${(props) => (props.transparent ? 'unset' : '#ffffff')};
` as OverridableComponent<
  BoxTypeMap<{
    transparent?: boolean;
    no_border?: boolean;
    border_color?: BorderColor;
    border_width?: number;
  }>
>;

export const EditHeader = styled(Box)<{ is_pc: string }>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: 64px;
  max-width: inherit;
  background: ${theme.palette.green.main};
  z-index: 12;
  ${(props) => props.is_pc && `border-radius: 40px 40px 0 0`}
`;

export const EditBody = styled(Box)`
  padding: 64px ${theme.spacing(3)} 0 ${theme.spacing(3)};
`;

export const EditFooter = styled(Box)`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 32px;
  width: 100%;
  height: 102px;
  max-width: inherit;
  background: #e3e9e6;
  z-index: 12;
`;

export const BaseContainer = styled(Box)<{ is_pc: string }>`
  display: flex;
  flex-direction: column;
  padding: 0 ${theme.spacing(3)};
  background: #f4f8f6;
  ${(props) => props.is_pc && `max-width: 506px;`}
  main {
    max-width: inherit;
    z-index: 1;
  }
` as OverridableComponent<
  ButtonTypeMap<{
    is_pc: string;
  }>
>;

export const NotEntered = styled(Box)`
  padding: 24px;
  width: 100%;
  height: calc((100vw - 48px) * 0.75);
  max-height: 285px;
  text-align: center;
  border-radius: 8px;
  background: url('/assets/images/common/bg_nofavorite.png') no-repeat;
  background-size: cover;
` as typeof Box;

export const NotEnteredReview = styled(Box)`
  padding: 24px;
  width: 100%;
  height: calc((100vw - 48px) * 0.75);
  max-height: 285px;
  text-align: center;
  border-radius: 8px;
  background: url('/assets/images/common/bg_noreview.jpg') no-repeat;
  background-size: cover;
` as typeof Box;

export const NotEnteredVisited = styled(Box)`
  padding: 24px;
  width: 100%;
  height: calc((100vw - 48px) * 0.75);
  max-height: 285px;
  text-align: center;
  border-radius: 8px;
  background: url('/assets/images/common/bg_novisited.jpg') no-repeat;
  background-size: cover;
` as typeof Box;

export const UserIcon = styled(Box)<{
  icon_height: number;
}>`
  img {
    height: ${(props) => props.icon_height && `${props.icon_height}`}px;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
  }
` as OverridableComponent<
  BoxTypeMap<{
    icon_height: number;
  }>
>;

export const HTMLArea = styled(Box)`
  img {
    width: 100%;
    height: 42vw;
    max-height: 214px;
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const Thumbnail = styled(Box)<{
  image_height: number;
  max_height?: number;
}>`
  img {
    width: 100%;
    height: ${(props) => props.image_height && `${props.image_height}`}vw;
    max-height: ${(props) =>
      props.max_height ? `${props.max_height}px` : '258px'};
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const ReviewBody = styled(Box)`
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
`;

export const RichTextArea = styled(Box)`
  img {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  ul,
  ol {
    padding-left: 21px;
    padding-bottom: 16px;
  }
  li {
    color: #333333;
    font-size: 15px;
    letter-spacing: 0.05em;
    line-height: 1.7;
  }
  ul > li {
    list-style: disc;
  }
  ol > li {
    list-style: decimal;
  }
  p {
    padding-bottom: 16px;
    color: #333333;
    font-size: 15px;
    letter-spacing: 0.05em;
    line-height: 1.7;
    :last-child {
      padding-bottom: 32px;
    }
  }
  h2 {
    padding-bottom: 32px;
    color: #333333;
    font-size: 16px;
    font-weight: 700;
  }
  h3,
  h4,
  h5 {
    padding-bottom: 32px;
    color: #333333;
    font-size: 15px;
    font-weight: 700;
  }
  strong,
  b {
    font-weight: 700;
  }
  a {
    color: rgb(42, 96, 126);
    letter-spacing: 0.05em;
    line-height: 1.7;
    text-decoration: underline;
    cursor: pointer;
  }
  strong > a {
    display: flex;
    padding-bottom: 32px;
    color: rgb(42, 96, 126);
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    :before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: url(/assets/images/icons/icon_arrowright.svg);
    }
  }
` as typeof Box;
