import type { UseQueryOptions } from '@tanstack/react-query';
import { gql } from 'graphql-request';

import type { CampsiteLikeFromPublicQuery } from '@/__generated__/public-graphql';
import { useCampsiteLikeFromPublicQuery } from '@/__generated__/public-graphql';

export default (
  user_id: string,
  options: UseQueryOptions<
    CampsiteLikeFromPublicQuery,
    unknown,
    CampsiteLikeFromPublicQuery
  >
) => {
  return useCampsiteLikeFromPublicQuery(
    {
      user_id,
    },
    {
      ...options,
      // cacheTime: 60 * 10,
    }
  );
};

const query = gql`
  query CampsiteLikeFromPublic($user_id: String!) {
    i_want_to_go: campsite_likes(
      where: { type: { _eq: "I_WANT_TO_GO" }, user_id: { _eq: $user_id } }
      order_by: { order_index: desc }
    ) {
      campsite_id
      created_at
      id
      type
      updated_at
      order_index
    }
    i_want_to_go_count: campsite_likes_aggregate(
      where: { type: { _eq: "I_WANT_TO_GO" }, user_id: { _eq: $user_id } }
    ) {
      aggregate {
        count
      }
    }
    special: campsite_likes(
      where: { type: { _eq: "SPECIAL" }, user_id: { _eq: $user_id } }
      order_by: { order_index: desc }
    ) {
      campsite_id
      created_at
      id
      type
      updated_at
      order_index
    }
    special_count: campsite_likes_aggregate(
      where: { type: { _eq: "SPECIAL" }, user_id: { _eq: $user_id } }
    ) {
      aggregate {
        count
      }
    }
    all: campsite_likes(
      where: { type: { _eq: "ALL" }, user_id: { _eq: $user_id } }
      order_by: { order_index: desc }
    ) {
      campsite_id
      created_at
      id
      type
      updated_at
      order_index
    }
    all_count: campsite_likes_aggregate(
      where: { type: { _eq: "ALL" }, user_id: { _eq: $user_id } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
