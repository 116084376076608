import {
  Divider,
  FormControl,
  FormGroup,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useContext, useState } from 'react';

import {
  useAddCampSiteLikeMutation,
  useGetMyPointNotificationQuery,
} from '@/__generated__/user-graphql';
import { Checkbox } from '@/contents/CampSite/styles';
import Button from '@/contents/General/Button';
import Icon from '@/contents/General/Icon';
import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import { GlobalContext } from '@/contents/hooks/state';
import useCampSiteLikeFromPublic from '@/modules/CampSiteLikeList/public/useCampSiteLikeFromPublic';
import useGetLoginUser from '@/modules/User/user/useGetLoginUser';
import theme from '@/styles/theme';
import { CampSiteLikeListType } from '@/types/domain';

import { EditFooter } from '../General/Panels';

const Container = styled(Box)<{ is_pc: string }>`
  position: fixed;
  width: 100%;
  height: 100vh;
  max-width: inherit;
  max-height: inherit;
  background: #f4f8f6;
  z-index: 11;
  overflow-y: auto;
  ${(props) => props.is_pc && `border-radius: 40px 40px 0 0;`}
`;

const Header = styled(Box)<{ is_pc: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: 64px;
  background: #e3e9e6;
  ${(props) => props.is_pc && `border-radius: 40px 40px 0 0;`}
`;

const FavoriteDialog = () => {
  const { dialogStatus, setDialogStatus } = useContext(GlobalContext);
  const { refetch: refetchGetMyPointNotification } =
    useGetMyPointNotificationQuery();

  const isPC = useMediaQuery('(min-width:900px)');
  const [checkedList, setCheckedList] = useState<CampSiteLikeListType[]>([
    CampSiteLikeListType.ALL,
  ]);
  const { user, isLogin } = useGetLoginUser();
  const campSiteId = dialogStatus.props.id;
  const { refetch: refetchCampSiteLike, data } = useCampSiteLikeFromPublic(
    user?.id ?? '',
    {}
  );
  const specialTotalCount = data?.special_count.aggregate?.count;
  const iWantToGoCount = data?.i_want_to_go_count.aggregate?.count;

  const close = () => {
    setDialogStatus({ ...dialogStatus, open: false });
  };
  const updateCheckedList = (
    checkListInput: CampSiteLikeListType,
    checked: boolean
  ) => {
    if (checked) {
      setCheckedList((prev) => {
        if (checkListInput === CampSiteLikeListType.ALL) {
          return [checkListInput];
        }

        return [...prev, checkListInput].filter(
          (item) => item !== CampSiteLikeListType.ALL
        );
      });
    } else {
      setCheckedList((prev) => {
        const result = prev.filter((p) => p !== checkListInput);
        if (result.length === 0) {
          return prev;
        }
        return prev.filter((p) => p !== checkListInput);
      });
    }
  };
  const addCampsiteLikeL = () => async () => {
    if (!campSiteId || !user?.id) {
      throw new Error('id is not found');
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const c of checkedList) {
      // eslint-disable-next-line no-await-in-loop
      await useAddCampSiteLikeMutation
        .fetcher({
          campsite_id: campSiteId,
          type: c,
          user_id: user?.id,
        })()
        .then(() => {
          refetchGetMyPointNotification();
        });
    }
    if (!checkedList.includes(CampSiteLikeListType.ALL)) {
      const allCampSiteIds = data?.all.map((campsite) => campsite.campsite_id);
      // campsitesの中にcampsite_idが含まれていない場合は、ALLを追加する
      if (!allCampSiteIds?.includes(campSiteId)) {
        await useAddCampSiteLikeMutation.fetcher({
          campsite_id: campSiteId,
          type: CampSiteLikeListType.ALL,
          user_id: user?.id,
        })();
      }
    }
    if (dialogStatus.props.refetchCampSite) {
      setTimeout(() => dialogStatus.props.refetchCampSite(), 600);
    }

    setTimeout(() => setDialogStatus({ ...dialogStatus, open: false }), 800);
    refetchCampSiteLike();
  };

  return (
    <Container is_pc={isPC ? isPC.toString() : ''}>
      <Header is_pc={isPC ? isPC.toString() : ''}>
        <IconButton
          onClick={close}
          sx={{ position: 'absolute', left: theme.spacing(3) }}
        >
          <Icon iconType="arrow-left" />
        </IconButton>
        <Typography fontSize={18} fontWeight={700}>
          お気に入り追加
        </Typography>
      </Header>
      <FormControl
        component="fieldset"
        sx={{
          padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
          width: '100%',
        }}
      >
        <FormGroup>
          <Box>
            <Stack
              between
              sx={{
                paddingRight: theme.spacing(1),
                paddingBottom: theme.spacing(3),
              }}
            >
              <Box>
                <Typography fontSize={18} fontWeight={700}>
                  すべてのお気に入り
                </Typography>
                <Typography fontSize={12} fontWeight={500} color="gray">
                  お気に入りの編集はマイページから！
                </Typography>
              </Box>
              <Checkbox
                value={'ALL'}
                icon={<Icon iconType="checkbox-round-off" />}
                checkedIcon={<Icon iconType="checkbox-round-on" />}
                onChange={(e) => {
                  updateCheckedList(CampSiteLikeListType.ALL, e.target.checked);
                }}
                checked={checkedList.includes(CampSiteLikeListType.ALL)}
              />
            </Stack>
          </Box>
          <Box
            sx={{
              marginBottom: theme.spacing(7),
            }}
          >
            <Stack
              between
              sx={{
                paddingBottom: theme.spacing(3),
              }}
            >
              <Typography fontSize={18} fontWeight={700}>
                自分のリスト
              </Typography>
            </Stack>
            <Divider />
            <Stack
              between
              sx={{ padding: `${theme.spacing(2)} ${theme.spacing(1)}` }}
            >
              <Stack alignCenter space={2}>
                <Icon iconType="favorite-flag" />
                <Box>
                  <Typography fontSize={16} fontWeight={500}>
                    行ってみたい
                  </Typography>
                  <Typography fontSize={12} fontWeight={500} color="gray">
                    {iWantToGoCount}
                    ヶ所
                  </Typography>
                </Box>
              </Stack>
              <Checkbox
                value={'I_WANT_TO_GO'}
                icon={<Icon iconType="checkbox-round-off" />}
                checkedIcon={<Icon iconType="checkbox-round-on" />}
                checked={checkedList.includes(
                  CampSiteLikeListType.I_WANT_TO_GO
                )}
                onChange={(e) => {
                  updateCheckedList(
                    CampSiteLikeListType.I_WANT_TO_GO,
                    e.target.checked
                  );
                }}
              />
            </Stack>
            <Divider />
            <Stack
              between
              sx={{ padding: `${theme.spacing(2)} ${theme.spacing(1)}` }}
            >
              <Stack alignCenter space={2}>
                <Icon iconType="favorite-star" />
                <Box>
                  <Typography fontSize={16} fontWeight={500}>
                    スペシャル
                  </Typography>
                  <Typography fontSize={12} fontWeight={500} color="gray">
                    {specialTotalCount}
                    ヶ所
                  </Typography>
                </Box>
              </Stack>
              <Checkbox
                value={'SPECIAL'}
                icon={<Icon iconType="checkbox-round-off" />}
                checkedIcon={<Icon iconType="checkbox-round-on" />}
                checked={checkedList.includes(CampSiteLikeListType.SPECIAL)}
                onChange={(e) => {
                  updateCheckedList(
                    CampSiteLikeListType.SPECIAL,
                    e.target.checked
                  );
                }}
              />
            </Stack>
            <Divider />
          </Box>
        </FormGroup>
      </FormControl>
      <EditFooter>
        <Button
          buttonSize="md"
          color="secondary"
          sx={{ width: '246px' }}
          onClick={addCampsiteLikeL()}
        >
          追加する
        </Button>
      </EditFooter>
    </Container>
  );
};

export default FavoriteDialog;
