import type { UseQueryOptions } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useEffect, useState } from 'react';

import type {
  SearchCampSiteQuery,
  SearchCampSiteQueryVariables,
} from '@/__generated__/public-graphql';
import { useSearchCampSiteQuery } from '@/__generated__/public-graphql';

export default (
  inputVariable: SearchCampSiteQueryVariables,
  options?: UseQueryOptions<SearchCampSiteQuery, unknown, SearchCampSiteQuery>
) => {
  const [variables, setVariables] =
    useState<SearchCampSiteQueryVariables>(inputVariable);
  const [campSites, setCampSites] = useState<SearchCampSiteQuery['campsite']>(
    []
  );
  const values = useSearchCampSiteQuery(
    {
      ...variables,
    },
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
  useEffect(() => {
    if (values.data?.campsite) {
      setCampSites(values.data.campsite);
    } else if (!values.isFetching) {
      setVariables(inputVariable);
    }
  }, [values]);

  return {
    ...values,
    campSites,
    total: values.data?.campsite_aggregate?.aggregate?.count ?? 0,
    executeQuery: (inputVariables: SearchCampSiteQueryVariables) => {
      setVariables(inputVariables);
    },
  };
};

const query = gql`
  query SearchCampSite(
    $limit: Int = 20
    $offset: Int = 0
    $where: campsite_bool_exp = {}
    $order_by: [campsite_order_by!] = { edited_at: desc }
  ) {
    campsite(
      limit: $limit
      order_by: $order_by
      offset: $offset
      where: $where
    ) {
      access_count
      activity_athletic
      activity_bbq
      activity_bicycle
      activity_canoe
      activity_craft
      activity_fishing
      activity_flower
      activity_hiking
      activity_insect
      activity_playset
      activity_pool
      activity_ranch
      activity_river
      activity_sauna
      activity_sea
      activity_star
      activity_taste
      activity_tentsauna
      address
      bath_hotspring
      bath_room
      business_newyear
      business_period
      checkin_start_hour
      checkin_start_minute
      checkin_end_hour
      checkin_end_minute
      checkout_start_hour
      checkout_start_minute
      checkout_end_hour
      checkout_end_minute
      closed_friday
      closed_irregular
      closed_holiday
      closed_monday
      closed_not
      closed_saturday
      campsite_images {
        campsite_id
        created_at
        id
        image_id
        type
        updated_at
        image {
          id
          alt
          created_at
          file_name
          title
          user_id
        }
      }
      closed_sunday
      closed_thursday
      closed_tuesday
      closed_wednesday
      created_at
      facilities_barrierfree
      facilities_dogrun
      facilities_laundry
      facilities_restaurant
      facilities_store
      facilities_workspace
      facility_acsite
      facility_bungalow
      facility_cottage
      facility_glamping
      facility_freesite
      facility_privatesite
      facility_solo
      facility_sectionsite
      facility_tipi
      facility_trailerhouse
      facility_treehouse
      id
      introduction_content
      introduction_title
      is_affiliated
      location_forest
      location_hill
      location_lake
      location_meadow
      location_ocean
      location_park
      image {
        user_id
        title
        id
        file_name
        created_at
        alt
      }
      location_river
      location_plateau
      main_image_id
      name
      official_url
      prefectures
      prefectures_code
      region_code
      service_firewood
      service_freecharge
      service_firework
      service_garbage
      service_group
      service_hotwater
      service_openfire
      service_pet
      service_rental
      service_washlet
      service_wifi
      tel_bottom
      tel_head
      tel_while
      total_like_num
      total_review_num
      total_visited_num
      updated_at
      vehicles_autocamp
      vehicles_campingcar
      visibility_status
      reviews(where: { public: { _eq: true } }) {
        campsite_id
        content
        created_at
        id
        public
        review_goods {
          created_at
          for_review_id
          from_user_id
          id
        }
        review_goods_aggregate {
          aggregate {
            count
          }
        }
        total_good_num
        updated_at
      }
    }
    campsite_aggregate(order_by: { updated_at: desc }, where: $where) {
      aggregate {
        count
      }
    }
  }
`;
