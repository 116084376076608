import 'swiper/css';
import 'swiper/css/navigation';

import { IconButton, Modal, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { A11y, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useUserByWhereQuery } from '@/__generated__/public-graphql';
import Icon from '@/contents/General/Icon';
import Image from '@/contents/General/Image';
import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import { GlobalContext } from '@/contents/hooks/state';
import theme from '@/styles/theme';
import type { SlideItem } from '@/types/domain';
import { getBlankName, getBlankUserIcon } from '@/utils/GetBlankString';
import ImageUrlConvert from '@/utils/ImageUrlConvert';

const StyledModal = styled(Modal)`
  & .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.75);
  }
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit- transform: translateY(-50%) translateX(-50%);
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${theme.spacing(5)};
  left: ${theme.spacing(3)};
`;

const StyledSwiper = styled(Swiper)`
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-slide-next {
    opacity: 0.7;
  }
  .swiper-slide-prev {
    opacity: 0.7;
  }
  .swiper-button-prev {
    z-index: 500;
    width: 30px;
    background: url('/assets/images/icons/icon_arrowleft_round.svg') no-repeat;
  }
  .swiper-button-next {
    z-index: 500;
    width: 30px;
    background: url('/assets/images/icons/icon_arrowright_round.svg') no-repeat;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    display: none;
  }
  .swiper-button-prev:focus,
  .swiper-button-next:focus {
    outline: none;
  }
`;

const ImageContainer = styled(Box)<{ is_pc: string }>`
  position: relative;
  margin: 48px auto 0;
  width: fit-content;
  overflow: hidden;
  text-align: center;
  border-radius: 8px;
  z-index: 200;
  ${(props) => (props.is_pc ? `max-width: 450px;` : `max-width: 90vw;`)}
  img {
    width: 100%;
    max-width: inherit;
    max-height: 55vh;
    border-radius: 8px;
    object-fit: contain;
  }
`;

const SwiperBackdrop = styled(Box)`
  position: absolute;
  top: -20%;
  width: 100%;
  height: 140%;
  zindex: 0;
`;

const SlideUserItem = ({ userId }: { userId: string }) => {
  const router = useRouter();
  const { dialogStatus, setDialogStatus } = useContext(GlobalContext);
  const { data } = useUserByWhereQuery({
    where: {
      id: {
        _eq: userId,
      },
    },
  });
  const user = data?.user_public[0];

  const routeCamperPage = (camperId: string) => {
    router.push(`/camper/${camperId}`);
    setDialogStatus({ ...dialogStatus, open: false });
  };

  return (
    <Stack
      onClick={() => routeCamperPage(userId)}
      center
      space={1}
      paddingTop={3}
      sx={{ cursor: 'pointer' }}
    >
      <Box
        sx={{
          height: '32px',
          borderRadius: '50%',
          overflow: 'hidden',
          background: '#ffffff',
        }}
      >
        <Image
          src={
            user?.icon?.file_name
              ? ImageUrlConvert(user.icon.file_name, true)
              : getBlankUserIcon()
          }
          width={32}
          height={32}
          alt={user?.display_name ?? ''}
        />
      </Box>
      <Typography fontSize={16} fontWeight={500} color="white">
        {user?.display_name ?? getBlankName()}
      </Typography>
    </Stack>
  );
};

const SlideDialog = () => {
  const { dialogStatus, setDialogStatus } = useContext(GlobalContext);
  const slideList = dialogStatus.props.slideList as SlideItem[];
  const initialIndex = dialogStatus.props.index as number;
  const isPC = useMediaQuery('(min-width:900px)');

  const close = () => {
    setDialogStatus({ ...dialogStatus, open: false });
  };

  return (
    <StyledModal open={dialogStatus.open} onClose={close}>
      <>
        <CloseButton onClick={close}>
          <Icon size={36} iconType="close-whg" />
        </CloseButton>
        <Container>
          <StyledSwiper
            modules={[Navigation, A11y]}
            slidesPerView={1}
            speed={700}
            loop
            navigation
            initialSlide={initialIndex}
            style={{ width: '100%' }}
          >
            {slideList.map((item, index) => (
              <SwiperSlide key={item.imageUrl + index}>
                <ImageContainer is_pc={isPC ? isPC.toString() : ''}>
                  <Image src={item.imageUrl} alt={item.title} />
                </ImageContainer>
                <Stack column center sx={{ position: 'relative', zIndex: 100 }}>
                  {item.title && (
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      color="white"
                      paddingTop={2}
                    >
                      {item.title}
                    </Typography>
                  )}
                  {
                    <>
                      <SlideUserItem userId={item.userId} />
                    </>
                  }
                </Stack>
                <SwiperBackdrop onClick={close} />
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </Container>
      </>
    </StyledModal>
  );
};

export default SlideDialog;
