import type { ButtonProps, ButtonTypeMap } from '@mui/material/Button';
import * as MuiButton from '@mui/material/Button';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';
import React from 'react';

import theme from '@/styles/theme';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
    green: true;
    line: true;
    amazon: true;
    facebook: true;
    transparent: true;
  }
}

type Props = ButtonProps & {
  buttonSize?: 'sm' | 'md' | 'lg' | 'round';
  component?: string;
};

const StyledButton = styled(MuiButton.default)<{
  buttonsize?: 'sm' | 'md' | 'lg' | 'round';
  component?: string;
}>`
  padding: 5px 11px;
  color: #ffffff;
  ${(props) =>
    props.buttonsize === 'sm' &&
    `height: 28px;
      font-size: 10px;
    `}
  ${(props) =>
    props.buttonsize === 'md' &&
    `min-width: 120px;
      height: 46px;
      font-size: 14px;
      font-weight: 700;
      border-radius: 8px;
    `}
  ${(props) =>
    props.buttonsize === 'lg' &&
    `min-width: 120px;
      height: 56px;
      font-size: 18px;
      font-weight: 700;
      border-radius: 8px;
    `}
  ${(props) =>
    props.buttonsize === 'round' &&
    `padding: 0 10px;
      min-width: 48px;
      height: 29px;
      color: #ffffff;
      font-size: 14px;
      border-radius: 15px;
      background: rgba(129, 137, 130, 0.8);
    `}
  ${(props) =>
    props.component === 'label' &&
    `padding: 0;
      min-width: 62px;
      background-color: unset;
      :hover {
        background-color: unset;
      }
    `}
  &.white {
    color: ${theme.palette.primary.main};
  }
  ${(props) =>
    props.color === 'transparent' &&
    `color: ${theme.palette.primary.main};
      border: solid 1px ${theme.palette.primary.main};
      background-color: unset;
      :hover {
        background-color: unset;
      }
    `}
` as OverridableComponent<
  ButtonTypeMap<{
    buttonsize?: 'sm' | 'md' | 'lg' | 'round';
    component?: string;
  }>
>;

const Button = ({
  children,
  onClick,
  disabled,
  color,
  startIcon,
  endIcon,
  fullWidth,
  type,
  sx,
  buttonSize,
  component,
}: Props) => {
  return (
    <StyledButton
      variant="contained"
      disableElevation
      onClick={onClick}
      disabled={disabled}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      fullWidth={fullWidth}
      sx={sx}
      buttonsize={buttonSize}
      className={color === 'white' ? 'white' : ''}
      component={component}
      type={type}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
