import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import OptimizedImage from '@/modules/General/OptimizedImage';

type Props = {
  src: string;
  alt: string;
  width?: number | '100%';
  height?: number | '100%';
  srcSet?: string;
  loading?: 'lazy';
  fill?: boolean;
};

const StyledBox = styled(Box)<{ fill?: string }>`
  img {
    vertical-align: middle;
  }
  ${(fill) =>
    fill &&
    `box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;
  z-index: -1;
  > img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
  }`}
`;

const Image = ({ src, srcSet, width, height, alt, loading, fill }: Props) => {
  return fill ? (
    <StyledBox fill={fill ? fill.toString() : undefined}>
      <OptimizedImage
        src={src}
        srcSet={srcSet}
        width={width}
        height={height}
        alt={alt}
        loading={loading}
      />
    </StyledBox>
  ) : (
    <OptimizedImage
      src={src}
      srcSet={srcSet}
      width={width}
      height={height}
      alt={alt}
      loading={loading}
    />
  );
};

export default Image;
