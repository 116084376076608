import { IconButton, Modal, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useContext } from 'react';

import Icon from '@/contents/General/Icon';
import Typography from '@/contents/General/Typography';
import { GlobalContext } from '@/contents/hooks/state';
import theme from '@/styles/theme';

const Header = styled(Box)<{ is_pc: string }>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  max-width: inherit;
  background: ${theme.palette.green.main};
  z-index: 12;
  ${(props) => props.is_pc && `border-radius: 40px 40px 0 0;`}
`;

const Body = styled(Box)`
  margin-top: 64px;
  padding-bottom: 166px;
  max-width: inherit;
  height: 100%;
  background: #f4f8f6;
  overflow-y: auto;
`;

const CommonThirdDialog = () => {
  const { dialogStatus, setDialogStatus } = useContext(GlobalContext);
  const isPC = useMediaQuery('(min-width:900px)');

  const title: string = dialogStatus.props.title || '';

  const close = () => {
    setDialogStatus({ ...dialogStatus, open: false });
  };

  return (
    <Modal open={dialogStatus.open} onClose={close}>
      <>
        <Header is_pc={isPC ? isPC.toString() : ''}>
          <IconButton
            onClick={close}
            sx={{ position: 'absolute', left: theme.spacing(3) }}
          >
            <Icon iconType="close-wh" />
          </IconButton>
          <Typography fontSize={18} fontWeight={700} color="white">
            {title}
          </Typography>
        </Header>
        <Body>{dialogStatus.children}</Body>
      </>
    </Modal>
  );
};

export default CommonThirdDialog;
