import Box from '@mui/material/Box';
import Link from 'next/link';
import { useRouter } from 'next/router';

import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import useGetLoginUser from '@/modules/User/user/useGetLoginUser';

const PCNavi = () => {
  const router = useRouter();
  const { user, isLogin } = useGetLoginUser();

  const nextPage = (path: string) => {
    router.push(path);
  };

  return (
    <Box>
      <Stack
        alignCenter
        space={4}
        paddingBottom={8}
        sx={{ cursor: 'pointer' }}
        onClick={() => nextPage('/')}
      >
        <img src="/assets/images/common/logo_md.svg" alt="" />
        <Typography fontSize={12} letterSpacing={3} lineHeight={2}>
          みんなでつくる
          <br />
          スマホの中のキャンプギア
        </Typography>
      </Stack>
      <Stack alignCenter space={1} paddingBottom={1}>
        <img
          src="/assets/images/icons/icon_arrowbottom_round_small.svg"
          alt=""
        />
        <Typography fontSize={12} fontWeight={700}>
          キャンプ場を探す
        </Typography>
      </Stack>
      <Stack column space={2.5} paddingBottom={6}>
        <Link href={'/theme'}>
          <a>
            <Typography fontSize={24} fontWeight={700}>
              テーマから見つける
            </Typography>
          </a>
        </Link>
        <Link href={'/search'}>
          <a>
            <Typography fontSize={24} fontWeight={700}>
              条件から探す
            </Typography>
          </a>
        </Link>
      </Stack>
      <Stack column space={2} paddingBottom={3}>
        <Typography
          onClick={() => nextPage('/luckybazaar')}
          fontSize={15}
          fontWeight={700}
          sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
        >
          ラッキーバザール
        </Typography>
        <Typography
          onClick={() => nextPage('/magazine')}
          fontSize={15}
          fontWeight={700}
          sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
        >
          Magazine「火種」
        </Typography>
        <Typography
          onClick={() => nextPage('/about')}
          fontSize={15}
          fontWeight={700}
          sx={{ cursor: 'pointer' }}
        >
          iihiについて
        </Typography>
        <Typography
          onClick={() => nextPage('/feedback')}
          fontSize={15}
          fontWeight={700}
          sx={{ cursor: 'pointer' }}
        >
          アイデア募集中
        </Typography>
        {/* 
        {user && user !== 'guest' && (
          <Link href={'/login'}>
            <a
              onClick={() => {
                Auth.signOut().then(() => {
                  router.push('/login');
                });
              }}
            >
              <Typography
                fontSize={15}
                fontWeight={700}
                sx={{ cursor: 'pointer' }}
              >
                ログアウト
              </Typography>
            </a>
          </Link>
        )} */}
      </Stack>
      <Stack alignEnd space={2} sx={{ position: 'absolute', bottom: '48px' }}>
        <Box
          sx={{
            p: '14px',
            width: '108px',
            height: '108px',
            borderRadius: 2,
            backgroundColor: '#ffffff',
          }}
        >
          <img src="/assets/images/common/qrcode.svg" alt="" />
        </Box>
        <Typography fontSize={12} fontWeight={500} lineHeight={1.5}>
          スマートフォンで
          <br />
          アクセスする
        </Typography>
      </Stack>
    </Box>
  );
};

export default PCNavi;
