import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import * as MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';

import Button from '@/contents/General/Button';
import Image from '@/contents/General/Image';
import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import useGetLoginUser from '@/modules/User/user/useGetLoginUser';
import theme from '@/styles/theme';

const Container = styled(Box)`
  padding-bottom: 102px;
  background: ${theme.palette.green.main};
`;

const BackToTopButton = styled(MuiButton.default)`
  position: relative;
  margin-left: 144px;
  min-width: unset;
  padding: unset;
` as typeof MuiButton.default;

export const Footer = () => {
  const router = useRouter();
  const { user, isLogin } = useGetLoginUser();
  const isSmallMobile = useMediaQuery('(max-width:360px)');

  const backToTop = () => {
    if (process.browser) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Container>
      <Stack
        column
        center
        sx={{
          marginBottom:
            router.pathname === '/camp/[id]' ? '120px' : theme.spacing(10),
        }}
      >
        {!user && (
          <>
            <Stack
              column
              center
              sx={{
                paddingTop: theme.spacing(6),
                paddingBottom: theme.spacing(4),
              }}
            >
              <Typography fontSize={16} color="white" fontWeight={500}>
                会員登録して、
              </Typography>
              <Typography fontSize={16} color="white" fontWeight={500}>
                自分だけのキャンプリストをつくりませんか？
              </Typography>
            </Stack>
            <Stack space={2}>
              <Button
                buttonSize="md"
                color="white"
                sx={{ width: isSmallMobile ? '150px' : '170px' }}
                onClick={() => router.push('/login')}
              >
                ログイン
              </Button>
              <Button
                buttonSize="md"
                color="secondary"
                sx={{ width: isSmallMobile ? '150px' : '170px' }}
                onClick={() => router.push('/login/?status=signup')}
              >
                会員登録
              </Button>
            </Stack>
          </>
        )}
        <Box paddingBottom={1.5} sx={{ paddingTop: theme.spacing(5) }}>
          <img
            src="/assets/images/common/logo-white.svg"
            width={75}
            height={43}
            alt="iihiロゴ"
          />
        </Box>
        <Stack alignCenter>
          <Typography fontSize={12} color="white">
            © iihi
          </Typography>
        </Stack>
        <Stack
          sx={{
            position: 'relative',
            top: '-28px',
            justifyContent: 'flex-end',
            pr: 3,
            width: '100%',
          }}
        >
          <BackToTopButton onClick={backToTop} component="label">
            <Image
              src="/assets/images/common/btn_backtop.svg"
              width={40}
              height={40}
              alt="TOPへ戻るボタン"
            />
          </BackToTopButton>
        </Stack>
      </Stack>
    </Container>
  );
};
