import { Button, IconButton, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import Icon from '@/contents/General/Icon';
import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import { GlobalContext } from '@/contents/hooks/state';
import theme from '@/styles/theme';

const StyledModal = styled(Modal)`
  & .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.75);
  }
`;

const Container = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 100%;
  max-width: 100%;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${theme.spacing(5)};
  left: ${theme.spacing(3)};
`;

const MessageButton = styled(Button)`
  justify-content: flex-start;
  padding: ${theme.spacing(2)} ${theme.spacing(3)};
  width: 87%;
  max-width: 380px;
  border-radius: 8px;
  background: #ffffff;
  &:hover {
    background: #ffffff;
  }
`;

const NotifyDialog = () => {
  const router = useRouter();
  const { dialogStatus, setDialogStatus } = useContext(GlobalContext);

  const close = () => {
    setDialogStatus({ ...dialogStatus, open: false });
  };

  const nextPage = (path: string) => {
    router.push(path);
    close();
  };

  return (
    <StyledModal open={dialogStatus.open} onClose={close}>
      <>
        <CloseButton onClick={close}>
          <Icon iconType="close-wh" />
        </CloseButton>
        <Container onClick={close}>
          <MessageButton
            onClick={() =>
              nextPage('/contact/user?category=問題のあるレビューの報告')
            }
          >
            <Stack alignCenter space={1}>
              <Icon iconType="mail" />
              <Typography fontSize={16} fontWeight={500}>
                運営に報告する
              </Typography>
            </Stack>
          </MessageButton>
          <Stack
            center
            paddingTop={3}
            onClick={() => nextPage('/guideline')}
            sx={{ cursor: 'pointer' }}
          >
            <Icon iconType="arrow-right" color="white" />
            <Typography fontSize={14} fontWeight={700} color="white">
              コミュニティガイドラインを確認する
            </Typography>
          </Stack>
        </Container>
      </>
    </StyledModal>
  );
};

export default NotifyDialog;
