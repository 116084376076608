import { Divider, IconButton, Modal, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import type { User } from '@sentry/nextjs';
import { Auth } from 'aws-amplify';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import Button from '@/contents/General/Button';
import Icon from '@/contents/General/Icon';
import Image from '@/contents/General/Image';
import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import { GlobalContext } from '@/contents/hooks/state';
import useSearchCampSite from '@/modules/CampSite/public/useSearchCampSite';
import useGetLoginUser from '@/modules/User/user/useGetLoginUser';
import theme from '@/styles/theme';
import { getBlankName, getBlankUserIcon } from '@/utils/GetBlankString';
import ImageUrlConvert from '@/utils/ImageUrlConvert';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  background: #ffffff;
  overflow-y: scroll;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${theme.spacing(5)};
  right: ${theme.spacing(3)};
`;

const ImageBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 4px 12px;
  width: 100%;
  height: 72px;
  border-radius: 8px;
  background: url('/assets/images/common/gnavi_img.jpg') no-repeat 50% 7%;
  background-size: 130%;
`;

const GlobalNavi = () => {
  const router = useRouter();
  const { dialogStatus, setDialogStatus } = useContext(GlobalContext);

  const isSmallMobile = useMediaQuery('(max-width:360px)');

  const close = () => {
    setDialogStatus({ ...dialogStatus, open: false });
  };
  const { user: loginUser, isLogin } = useGetLoginUser();
  const { total: num } = useSearchCampSite({
    where: {
      main_image_id: {
        _is_null: true,
      },
    },
  });

  const nextPage = (path: string) => {
    router.push(path);
    close();
  };

  return (
    <Modal open={dialogStatus.open} onClose={close}>
      <>
        <CloseButton onClick={close}>
          <Icon size={36} iconType="close-whg" />
        </CloseButton>
        <Container>
          <Stack column space={3} padding={3}>
            {loginUser && typeof loginUser === 'object' ? (
              <Stack
                alignCenter
                space={1.5}
                onClick={() => nextPage(`/camper/${(loginUser as User).id}`)}
                sx={{ cursor: 'pointer' }}
              >
                {isLogin && loginUser.icon?.file_name ? (
                  <Box
                    sx={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '50%',
                      overflow: 'hidden',
                      '& img': {
                        objectFit: 'cover',
                      },
                    }}
                  >
                    <Image
                      src={ImageUrlConvert(loginUser.icon?.file_name, true)}
                      width={60}
                      height={60}
                      alt={`${loginUser.display_name}のアイコン`}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '50%',
                      overflow: 'hidden',
                      '& img': {
                        objectFit: 'cover',
                      },
                    }}
                  >
                    <Image
                      src={getBlankUserIcon()}
                      width={60}
                      height={60}
                      alt=""
                    />
                  </Box>
                )}
                <Box>
                  <Typography
                    fontSize={16}
                    fontWeight={700}
                    paddingBottom={0.25}
                  >
                    {(isLogin && loginUser.display_name) ?? getBlankName()}
                  </Typography>
                  <Typography fontSize={12} fontWeight={500} color="#818982">
                    マイページ
                  </Typography>
                </Box>
              </Stack>
            ) : (
              <>
                <Stack between>
                  <Button
                    onClick={() => nextPage('/login')}
                    buttonSize="md"
                    color="primary"
                    sx={{ width: isSmallMobile ? '110px' : '120px' }}
                  >
                    ログイン
                  </Button>
                  <Button
                    onClick={() => nextPage('/login/?status=signup')}
                    buttonSize="md"
                    color="secondary"
                    sx={{ width: isSmallMobile ? '110px' : '120px' }}
                  >
                    会員登録
                  </Button>
                </Stack>
              </>
            )}
            <Divider />
            <Box>
              <Stack alignCenter space={1} paddingBottom={1.6}>
                <img
                  src="/assets/images/icons/icon_arrowbottom_round_small.svg"
                  alt=""
                />
                <Typography fontSize={12} fontWeight={700}>
                  キャンプ場を探す
                </Typography>
              </Stack>
              <Stack column space={2} paddingBottom={4}>
                <Link href={'/theme'}>
                  <a onClick={close}>
                    <Typography fontSize={24} fontWeight={700}>
                      テーマから見つける
                    </Typography>
                  </a>
                </Link>
                <Link href={'/search'}>
                  <a onClick={close}>
                    <Typography fontSize={24} fontWeight={700}>
                      条件から探す
                    </Typography>
                  </a>
                </Link>
              </Stack>
              <Stack
                column
                space={2}
                paddingBottom={3}
                sx={{ maxWidth: '252px' }}
              >
                <Link href={'/luckybazaar'}>
                  <a onClick={close}>
                    <Typography
                      fontSize={15}
                      fontWeight={700}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      ラッキーバザール
                    </Typography>
                  </a>
                </Link>
                <Link href={'/magazine'}>
                  <a onClick={close}>
                    <Typography
                      fontSize={15}
                      fontWeight={700}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      Magazine「火種」
                    </Typography>
                  </a>
                </Link>
                <Link href={'/about'}>
                  <a onClick={close}>
                    <Typography fontSize={15} fontWeight={700}>
                      iihiについて
                    </Typography>
                  </a>
                </Link>
                <Link href={'/feedback'}>
                  <a onClick={close}>
                    <Typography fontSize={15} fontWeight={700}>
                      アイデア募集中
                    </Typography>
                  </a>
                </Link>
              </Stack>
              <Stack column space={1.5} paddingBottom={4}>
                <Link href={'/news'}>
                  <a onClick={close}>
                    <Typography fontSize={12} fontWeight={500}>
                      お知らせ
                    </Typography>
                  </a>
                </Link>
                <Link href={'/faq'}>
                  <a onClick={close}>
                    <Typography fontSize={12} fontWeight={500}>
                      よくあるご質問
                    </Typography>
                  </a>
                </Link>
                <Link href={'/contact/user'}>
                  <a onClick={close}>
                    <Typography fontSize={12} fontWeight={500}>
                      お問い合わせ
                    </Typography>
                  </a>
                </Link>
                <Link href={'/terms'}>
                  <a onClick={close}>
                    <Typography fontSize={12} fontWeight={500}>
                      利用規約
                    </Typography>
                  </a>
                </Link>
                {/* <Typography
                  onClick={() => nextPage('/tokushoho')}
                  fontSize={12}
                  fontWeight={500}
                  sx={{ cursor: 'pointer' }}
                >
                  特定商取引法に基づく表示
                </Typography> */}
                <Link href={'/privacy'}>
                  <a onClick={close}>
                    <Typography fontSize={12} fontWeight={500}>
                      プライバシーポリシー
                    </Typography>
                  </a>
                </Link>
                <Link href={'/guideline'}>
                  <a onClick={close}>
                    <Typography fontSize={12} fontWeight={500}>
                      iihiを楽しむためのガイド
                    </Typography>
                  </a>
                </Link>
                {loginUser && (
                  <Link href={'/login'}>
                    <a
                      onClick={() => {
                        Auth.signOut().then(() => {
                          router.push('/login');
                        });
                        close();
                      }}
                    >
                      <Typography fontSize={12} fontWeight={500}>
                        ログアウト
                      </Typography>
                    </a>
                  </Link>
                )}
              </Stack>
              <Button
                component="label"
                onClick={() => nextPage('/search/notentered/')}
                fullWidth
              >
                <ImageBox>
                  <Typography fontSize={12} fontWeight={500} color="white">
                    キャンプ場の写真・情報募集中!
                  </Typography>
                  <Stack alignEnd space={0.5}>
                    <Typography fontSize={12} fontWeight={700} color="white">
                      残り
                    </Typography>
                    <Typography
                      fontSize={34}
                      fontWeight={900}
                      lineHeight={1}
                      color="white"
                    >
                      {num}
                    </Typography>
                    <Typography fontSize={12} fontWeight={700} color="white">
                      施設
                    </Typography>
                  </Stack>
                </ImageBox>
              </Button>
            </Box>
            <Stack space={3}>
              <Button
                onClick={() =>
                  window.open('https://www.instagram.com/iihi.camp/', '_blank')
                }
                component="label"
                sx={{ minWidth: 'unset', padding: 'unset' }}
              >
                <Image
                  src="/assets/images/common/btn_insta.svg"
                  width={48}
                  height={48}
                  alt=""
                />
              </Button>
              <Button
                onClick={() =>
                  window.open('https://note.com/iihi_camp/', '_blank')
                }
                component="label"
                sx={{ minWidth: 'unset', padding: 'unset' }}
              >
                <Image
                  src="/assets/images/common/btn_note.svg"
                  width={48}
                  height={48}
                  alt=""
                />
              </Button>
            </Stack>
          </Stack>
        </Container>
      </>
    </Modal>
  );
};

export default GlobalNavi;
