import { Box } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';

import type { Campsite } from '@/__generated__/public-graphql';
import Icon from '@/contents/General/Icon';
import Typography from '@/contents/General/Typography';
import type { PublicUser, User } from '@/types/domain';
import type {
  FaqcategoryDocument,
  FaqDocument,
  NotificationDocument,
  PostDocument,
  ThemeDocument,
} from '@/types/prismic.generated';
import { getBlankName } from '@/utils/GetBlankString';

type Props = {
  campSite?: Campsite;
  user?: PublicUser;
  notification?: NotificationDocument;
  post?: PostDocument;
  faqCategory?: FaqcategoryDocument;
  faq?: FaqDocument;
  theme?: ThemeDocument;
  gacha?: any;
};

const BreadCrumb = ({
  campSite,
  user,
  notification,
  post,
  faq,
  faqCategory,
  theme,
  gacha,
}: Props) => {
  const breadCrumbs: { link?: string; label: string }[] = [];
  const router = useRouter();

  const { pathname, query } = router;
  const queryPath = (query.path && (query.path as string)) ?? '';
  if (pathname.match(/\/camper/) !== null && !!user) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: user.display_name ?? getBlankName(),
      link: `/camper/${user.id}`,
    });
    if (pathname.match(/favorite/)) {
      breadCrumbs.push({
        label: 'お気に入り',
        link: `/camper/${(user as User).id}/favorite`,
      });
    } else if (pathname.match(/log/)) {
      breadCrumbs.push({
        label: 'レビュー',
        link: `/camper/${(user as User).id}/log`,
      });
    } else if (pathname.match(/visited/)) {
      breadCrumbs.push({
        label: '過去に行った！キャンプ場',
        link: `/camper/${(user as User).id}/visited`,
      });
    } else if (pathname.match(/history/)) {
      breadCrumbs.push({
        label: '編集・写真投稿した施設',
        link: `/camper/${(user as User).id}/history`,
      });
    } else if (pathname.match(/seeds/)) {
      breadCrumbs.push({
        label: 'マイシーズ',
        link: `/camper/${(user as User).id}/seeds`,
      });
    } else if (pathname.match(/gift/)) {
      breadCrumbs.push({
        label: '当選したロトシーズ',
        link: `/camper/${(user as User).id}/gift`,
      });
    }
  } else if (pathname.match(/\/camp\//) && !!campSite) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: campSite.name,
      link: `/camp/${campSite.id}`,
    });
    if (pathname.match(/log/) !== null) {
      breadCrumbs.push({
        label: 'レビュー',
      });
    } else if (pathname.match(/edit/) !== null) {
      breadCrumbs.push({
        label: '情報記入・編集',
      });
    } else if (pathname.match(/photo/) !== null) {
      breadCrumbs.push({
        label: '写真登録',
      });
    }
  } else if (pathname.match(/search/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: '検索',
      link: `/search`,
    });
  } else if (pathname.match(/news/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: 'お知らせ',
      link: `/news`,
    });
    if (notification) {
      breadCrumbs.push({
        label: notification.data.title ?? '',
      });
    }
  } else if (pathname.match(/magazine/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: 'ウェブマガジン',
      link: `/magazine`,
    });
    if (post) {
      breadCrumbs.push({
        label: post.data.title ?? '',
      });
    }
  } else if (pathname.match(/faq/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: 'よくあるご質問',
      link: `/faq`,
    });
    if (faqCategory) {
      breadCrumbs.push({
        label: faqCategory.data.title ?? '',
      });
    }
    if (faq) {
      breadCrumbs.push({
        label: faq?.data?.title ?? '',
      });
    }
  } else if (pathname.match(/theme/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: '見つける',
      link: `/theme`,
    });
    if (theme) {
      breadCrumbs.push({
        label: theme?.data?.title ?? '',
      });
    }
  } else if (pathname.match(/\/contact/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    if (queryPath.match(/user/) !== null) {
      breadCrumbs.push({
        label: 'お問い合わせ',
        link: `/contact/user`,
      });
    } else if (queryPath.match(/company/)) {
      breadCrumbs.push({
        label: 'お問い合わせ',
        link: `/contact/company`,
      });
    }
  } else if (pathname.match(/feedback/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: 'アイデア募集中',
      link: `/feedback`,
    });
  } else if (pathname.match(/^\/about/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: 'iihiについて',
      link: `/about`,
    });
    if (pathname.match(/about\/character/)) {
      breadCrumbs.push({
        label: 'iihiの仲間たち',
      });
    }
  } else if (pathname.match(/terms/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: '利用規約',
      link: `/terms`,
    });
  } else if (pathname.match(/tokushoho/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: '特定商取引法に基づく表記',
    });
  } else if (pathname.match(/privacy/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: 'プライバシーポリシー',
    });
  } else if (pathname.match(/guideline/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: 'iihiを楽しむためのガイド',
    });
  } else if (pathname.match(/^\/luckybazaar/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: 'iihiラッキーバザール',
      link: `/luckybazaar`,
    });
    if (pathname.match(/luckybazaar\/about/)) {
      breadCrumbs.push({
        label: 'シーズとは',
        link: `/luckybazaar/about`,
      });
    }
    if (gacha) {
      breadCrumbs.push({
        label: gacha.title ?? '',
      });
    }
    if (pathname.match(/sponsor/)) {
      breadCrumbs.push({
        label: 'ふくびき当選品の募集',
        link: `/luckybazaar/sponsor`,
      });
    }
    if (pathname.match(/firewood/)) {
      breadCrumbs.push({
        label: '薪1束プレゼント',
        link: `/luckybazaar/firewood`,
      });
    }
  } else if (pathname.match(/^\/partnership/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: 'iihi提携キャンプ場',
      link: `/partnership`,
    });
  } else if (pathname.match(/^\/illust/)) {
    breadCrumbs.push({
      label: 'ホーム',
      link: `/`,
    });
    breadCrumbs.push({
      label: 'iihiフリーイラスト',
      link: `/illust`,
    });
    if (pathname.match(/list/)) {
      breadCrumbs.push({
        label: 'イラスト素材一覧',
        link: `/illust/list`,
      });
    }
    if (pathname.match(/download/)) {
      breadCrumbs.push({
        label: 'イラスト素材ダウンロード',
      });
    }
  }
  return (
    <span style={{ zIndex: '-1' }}>
      {breadCrumbs.map((item, index) => (
        <span key={`${item.label + index}`}>
          {!item.link ? (
            <Typography
              color="gray"
              fontSize={12}
              fontWeight={500}
              sx={{ display: 'inline' }}
            >
              {item.label}
            </Typography>
          ) : (
            <Link href={item.link}>
              <a>
                <Typography
                  color="gray"
                  fontSize={12}
                  fontWeight={500}
                  sx={{ display: 'inline' }}
                >
                  {item.label}
                </Typography>
              </a>
            </Link>
          )}
          {breadCrumbs.length !== index + 1 && (
            <Box component="span" sx={{ position: 'relative', top: '7px' }}>
              <Icon iconType="arrow-right" color="grey" />
            </Box>
          )}
        </span>
      ))}
    </span>
  );
};

export default BreadCrumb;
