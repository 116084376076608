import { IconButton, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useContext } from 'react';

import Icon from '@/contents/General/Icon';
import { GlobalContext } from '@/contents/hooks/state';
import theme from '@/styles/theme';

const StyledModal = styled(Modal)`
  &.MuiModal-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  & .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.75);
  }
`;

const Container = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  max-width: 100%;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${theme.spacing(5)};
  left: ${theme.spacing(3)};
`;

const CommonDialog = () => {
  const { dialogStatus, setDialogStatus } = useContext(GlobalContext);
  const close = () => {
    setDialogStatus({ ...dialogStatus, open: false });
  };

  return (
    <StyledModal open={dialogStatus.open} onClose={close}>
      <>
        <CloseButton onClick={close}>
          <Icon size={36} iconType="close-whg" />
        </CloseButton>
        <Container sx={dialogStatus.widthFull ? { width: '100%' } : {}}>
          <Box sx={{ padding: theme.spacing(3), width: '100%' }}>
            {dialogStatus.children}
          </Box>
        </Container>
      </>
    </StyledModal>
  );
};

export default CommonDialog;
