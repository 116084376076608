export const preventScroll = (open: boolean) => {
  const htmlEl = document.getElementsByTagName('html');
  if (htmlEl[0]) {
    if (open) {
      // htmlEl[0].style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    } else {
      // htmlEl[0].removeAttribute('style');
      document.body.removeAttribute('style');
    }
  }
};
