import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React from 'react';

import SvgAdd from '@/public/assets/images/icons/icon_add.svg';
import SvgArrowBottom from '@/public/assets/images/icons/icon_arrowbottom.svg';
import SvgArrowBottomDouble from '@/public/assets/images/icons/icon_arrowbottom_double.svg';
import SvgArrowLeft from '@/public/assets/images/icons/icon_arrowleft.svg';
import SvgArrowLeftRoundPrimary from '@/public/assets/images/icons/icon_arrowleft_round_primary.svg';
import SvgArrowRight from '@/public/assets/images/icons/icon_arrowright.svg';
import SvgArrowRightRoundPrimary from '@/public/assets/images/icons/icon_arrowright_round_primary.svg';
import SvgArrowTop from '@/public/assets/images/icons/icon_arrowtop.svg';
import SvgArrowTopDouble from '@/public/assets/images/icons/icon_arrowtop_double.svg';
import SvgCheckboxIndeterminateOff from '@/public/assets/images/icons/icon_checkbox_indeterminate_off.svg';
import SvgCheckboxIndeterminateOn from '@/public/assets/images/icons/icon_checkbox_indeterminate_on.svg';
import SvgCheckboxOff from '@/public/assets/images/icons/icon_checkbox_off.svg';
import SvgCheckboxOn from '@/public/assets/images/icons/icon_checkbox_on.svg';
import SvgCheckboxRoundOff from '@/public/assets/images/icons/icon_checkbox_round_off.svg';
import SvgCheckboxRoundOn from '@/public/assets/images/icons/icon_checkbox_round_on.svg';
import SvgCircle from '@/public/assets/images/icons/icon_circle.svg';
import SvgCloseRoundGrey from '@/public/assets/images/icons/icon_close_round_grey.svg';
import SvgCloseRoundRed from '@/public/assets/images/icons/icon_close_round_red.svg';
import SvgCloseRoundWhite from '@/public/assets/images/icons/icon_close_round_white.svg';
import SvgCloseRoundWhiteGrey from '@/public/assets/images/icons/icon_close_round_white_grey.svg';
import SvgDot from '@/public/assets/images/icons/icon_dot.svg';
import SvgDots from '@/public/assets/images/icons/icon_dots.svg';
import SvgDotsLarge from '@/public/assets/images/icons/icon_dots_lg.svg';
import SvgEditAlert from '@/public/assets/images/icons/icon_edit_alert.svg';
import SvgEditCircle from '@/public/assets/images/icons/icon_edit_circle.svg';
import SvgEditEntered from '@/public/assets/images/icons/icon_edit_entered.svg';
import SvgEditNotEntered from '@/public/assets/images/icons/icon_edit_notentered.svg';
import SvgMypagePencil from '@/public/assets/images/icons/icon_edit_pencil.svg';
import SvgFavorite from '@/public/assets/images/icons/icon_favorite.svg';
import SvgFavoriteBase from '@/public/assets/images/icons/icon_favorite_base.svg';
import SvgFavoriteFlag from '@/public/assets/images/icons/icon_favorite_flag.svg';
import SvgFavoriteStar from '@/public/assets/images/icons/icon_favorite_star.svg';
import SvgFile from '@/public/assets/images/icons/icon_file.svg';
import SvgFilter from '@/public/assets/images/icons/icon_filter.svg';
import SvgHumberger from '@/public/assets/images/icons/icon_humberger.svg';
import SvgImage from '@/public/assets/images/icons/icon_image.svg';
import SvgKeyChain from '@/public/assets/images/icons/icon_keychain.svg';
import SvgLike from '@/public/assets/images/icons/icon_like.svg';
import SvgLink from '@/public/assets/images/icons/icon_link.svg';
import SvgMail from '@/public/assets/images/icons/icon_mail.svg';
import SvgMenu from '@/public/assets/images/icons/icon_menu.svg';
import SvgMinus from '@/public/assets/images/icons/icon_minus.svg';
import SvgMypageAccount from '@/public/assets/images/icons/icon_mypage_account.svg';
import SvgMypageFavorite from '@/public/assets/images/icons/icon_mypage_favorite.svg';
import SvgMypageReview from '@/public/assets/images/icons/icon_mypage_review.svg';
import SvgMypageVisit from '@/public/assets/images/icons/icon_mypage_visit.svg';
import SvgNotFavorite from '@/public/assets/images/icons/icon_not_favorite.svg';
import SvgNotification from '@/public/assets/images/icons/icon_notification.svg';
import SvgPencil from '@/public/assets/images/icons/icon_pencil.svg';
import SvgPerson from '@/public/assets/images/icons/icon_person.svg';
import SvgRadioOff from '@/public/assets/images/icons/icon_radio_off.svg';
import SvgRadioOn from '@/public/assets/images/icons/icon_radio_on.svg';
import SvgRoundMinus from '@/public/assets/images/icons/icon_round_minus.svg';
import SvgRoundPlus from '@/public/assets/images/icons/icon_round_plus.svg';
import SvgRoundPlusOutline from '@/public/assets/images/icons/icon_round_plus_outline.svg';
import SvgSearch from '@/public/assets/images/icons/icon_search.svg';
import SvgSearchRound from '@/public/assets/images/icons/icon_search_round.svg';
import SvgSetting from '@/public/assets/images/icons/icon_setting.svg';
import SvgSNSAmazon from '@/public/assets/images/icons/icon_sns_amazon.svg';
import SvgSNSFacebook from '@/public/assets/images/icons/icon_sns_facebook.svg';
import SvgSNSGoogle from '@/public/assets/images/icons/icon_sns_google.svg';
import SvgSNSLine from '@/public/assets/images/icons/icon_sns_line.svg';
import SvgSNSLineFriend from '@/public/assets/images/icons/icon_sns_line_friend.svg';
import SvgSwitch from '@/public/assets/images/icons/icon_switch.svg';
import SvgTent from '@/public/assets/images/icons/icon_tent.svg';
import SvgTrash from '@/public/assets/images/icons/icon_trash.svg';
import SvgUpload from '@/public/assets/images/icons/icon_upload.svg';
import theme from '@/styles/theme';
import type { IconType } from '@/types/domain';

type Props = {
  iconType: IconType;
  size?: number;
  color?: string;
  sx?: any;
};

const getColor = (svgColor: string) => {
  switch (svgColor) {
    case 'primary':
      return theme.palette.primary.main;
    case 'secondary':
      return theme.palette.secondary.main;
    case 'white':
      return theme.palette.white.main;
    case 'grey':
      return theme.palette.grey[100];
    case 'grey-50':
      return theme.palette.grey[50];
    case 'red':
      return theme.palette.red.main;
    default:
      return '';
  }
};

const Container = styled(Box)<{ size?: number; svg_color?: string }>`
  display: inherit;
  ${(props) =>
    props.svg_color &&
    `svg > path:not(:first-of-type),
      svg > g > path:not(:first-of-type),
      svg > g > circle {
        fill: ${getColor(props.svg_color)} !important;
      }
      svg > g > g > path {
        fill: ${getColor(props.svg_color)} !important;
      },
      svg > g > g > rect {
        fill: ${getColor(props.svg_color)} !important;
      }
  `}
  ${(props) =>
    props.size &&
    `svg {
      width: ${props.size}px;
      height: ${props.size}px;
    }
  `}
`;

const Icon = ({ iconType, size, color, sx }: Props) => {
  const SwitchIcon = () => {
    switch (iconType) {
      case 'add':
        return <SvgAdd />;
      case 'arrow-top-double':
        return <SvgArrowTopDouble />;
      case 'arrow-bottom-double':
        return <SvgArrowBottomDouble />;
      case 'arrow-top':
        return <SvgArrowTop />;
      case 'arrow-bottom':
        return <SvgArrowBottom />;
      case 'arrow-right':
        return <SvgArrowRight />;
      case 'arrow-right-primary':
        return <SvgArrowRightRoundPrimary />;
      case 'arrow-left':
        return <SvgArrowLeft />;
      case 'arrow-left-primary':
        return <SvgArrowLeftRoundPrimary />;
      case 'close-wh':
        return <SvgCloseRoundWhite />;
      case 'close-whg':
        return <SvgCloseRoundWhiteGrey />;
      case 'close-gr':
        return <SvgCloseRoundGrey />;
      case 'close-red':
        return <SvgCloseRoundRed />;
      case 'checkbox-on':
        return <SvgCheckboxOn />;
      case 'checkbox-off':
        return <SvgCheckboxOff />;
      case 'checkbox-round-on':
        return <SvgCheckboxRoundOn />;
      case 'checkbox-round-off':
        return <SvgCheckboxRoundOff />;
      case 'checkbox-it-on':
        return <SvgCheckboxIndeterminateOn />;
      case 'checkbox-it-off':
        return <SvgCheckboxIndeterminateOff />;
      case 'circle':
        return <SvgCircle />;
      case 'dot':
        return <SvgDot />;
      case 'dots':
        return <SvgDots />;
      case 'dots-lg':
        return <SvgDotsLarge />;
      case 'edit-notentered':
        return <SvgEditNotEntered />;
      case 'edit-entered':
        return <SvgEditEntered />;
      case 'edit-alert':
        return <SvgEditAlert />;
      case 'edit-circle':
        return <SvgEditCircle />;
      case 'edit-pencil':
        return <SvgMypagePencil />;
      case 'favorite':
        return <SvgFavorite />;
      case 'not-favorite':
        return <SvgNotFavorite />;
      case 'favorite-base':
        return <SvgFavoriteBase />;
      case 'favorite-flag':
        return <SvgFavoriteFlag />;
      case 'favorite-star':
        return <SvgFavoriteStar />;
      case 'file':
        return <SvgFile />;
      case 'filter':
        return <SvgFilter />;
      case 'humberger':
        return <SvgHumberger />;
      case 'image':
        return <SvgImage />;
      case 'like':
        return <SvgLike />;
      case 'link':
        return <SvgLink />;
      case 'mail':
        return <SvgMail />;
      case 'menu':
        return <SvgMenu />;
      case 'minus':
        return <SvgMinus />;
      case 'mypage-account':
        return <SvgMypageAccount />;
      case 'mypage-favorite':
        return <SvgMypageFavorite />;
      case 'mypage-review':
        return <SvgMypageReview />;
      case 'mypage-visit':
        return <SvgMypageVisit />;
      case 'notification':
        return <SvgNotification />;
      case 'pencil':
        return <SvgPencil />;
      case 'person':
        return <SvgPerson />;
      case 'radio-on':
        return <SvgRadioOn />;
      case 'radio-off':
        return <SvgRadioOff />;
      case 'round-minus':
        return <SvgRoundMinus />;
      case 'round-plus':
        return <SvgRoundPlus />;
      case 'round-plus-outline':
        return <SvgRoundPlusOutline />;
      case 'tent':
        return <SvgTent />;
      case 'trash':
        return <SvgTrash />;
      case 'key':
        return <SvgKeyChain />;
      case 'search':
        return <SvgSearch />;
      case 'search-round':
        return <SvgSearchRound />;
      case 'setting':
        return <SvgSetting />;
      case 'switch':
        return <SvgSwitch />;
      case 'sns-facebook':
        return <SvgSNSFacebook />;
      case 'sns-amazon':
        return <SvgSNSAmazon />;
      case 'sns-line':
        return <SvgSNSLine />;
      case 'sns-line-friend':
        return <SvgSNSLineFriend />;
      case 'sns-google':
        return <SvgSNSGoogle />;
      case 'upload':
        return <SvgUpload />;
      default:
        return <></>;
    }
  };

  return (
    <Container size={size} svg_color={color} sx={sx}>
      {SwitchIcon()}
    </Container>
  );
};

export default Icon;
