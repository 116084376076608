import 'swiper/css';
import 'swiper/css/pagination';

import { InputAdornment, styled } from '@mui/material';
import Box from '@mui/material/Box';
import type { User } from '@sentry/nextjs';
import { Auth } from 'aws-amplify';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

import Button from '@/contents/General/Button';
import Icon from '@/contents/General/Icon';
import Image from '@/contents/General/Image';
import { UserIcon } from '@/contents/General/Panels';
import Stack from '@/contents/General/Stack';
import TextField from '@/contents/General/TextField';
import Typography from '@/contents/General/Typography';
import { GlobalContext } from '@/contents/hooks/state';
import useSearchCampSite from '@/modules/CampSite/public/useSearchCampSite';
import useGetLoginUser from '@/modules/User/user/useGetLoginUser';
import { getBlankName, getBlankUserIcon } from '@/utils/GetBlankString';
import ImageUrlConvert from '@/utils/ImageUrlConvert';

const ImageBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  width: 100%;
  height: 83px;
  border-radius: 8px;
  background: url('/assets/images/common/gnavi_img.jpg') no-repeat;
  background-size: cover;
`;

const PCSuggest = () => {
  const router = useRouter();
  const { suggestText, setSuggestText, setOpenSuggest } =
    useContext(GlobalContext);
  const { user: loginUser, isLogin } = useGetLoginUser();
  const { total: num } = useSearchCampSite({
    where: {
      main_image_id: {
        _is_null: true,
      },
    },
  });
  const { dialogStatus, setDialogStatus } = useContext(GlobalContext);

  const close = () => {
    setDialogStatus({ ...dialogStatus, open: false });
  };
  useEffect(() => {
    if (suggestText !== '') {
      setOpenSuggest(true);
    } else {
      setOpenSuggest(false);
    }
  }, [suggestText]);

  return (
    <Stack column between width={360} height={'calc(100vh - 80px)'}>
      <Stack column space={4}>
        <Box>
          <TextField
            placeholder="キャンプ場名やエリアを入力"
            value={suggestText}
            onChange={(e) => setSuggestText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon iconType="search" size={32} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {loginUser && typeof loginUser === 'object' ? (
          <Stack
            alignCenter
            space={1.5}
            onClick={() => router.push(`/camper/${(loginUser as User).id}`)}
            sx={{ cursor: 'pointer' }}
          >
            {isLogin && loginUser?.icon?.file_name ? (
              <UserIcon icon_height={60}>
                <Image
                  src={ImageUrlConvert(loginUser?.icon?.file_name, true)}
                  width={60}
                  height={60}
                  alt={`${loginUser.display_name}のアイコン`}
                />
              </UserIcon>
            ) : (
              <Box
                sx={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  background: '#FFFFFF',
                }}
              >
                <Image
                  src={getBlankUserIcon()}
                  width={60}
                  height={60}
                  alt="未設定のユーザーアイコン"
                />
              </Box>
            )}
            <Box>
              <Typography fontSize={16} fontWeight={700} paddingBottom={0.25}>
                {(isLogin && loginUser.display_name) ?? getBlankName()}
              </Typography>
              <Typography fontSize={12} fontWeight={500} color="#818982">
                マイページ
              </Typography>
            </Box>
          </Stack>
        ) : (
          <>
            <Stack between>
              <Button
                buttonSize="md"
                color="primary"
                sx={{ width: '160px' }}
                onClick={() => router.push('/login')}
              >
                ログイン
              </Button>
              <Button
                buttonSize="md"
                color="secondary"
                sx={{ width: '160px' }}
                onClick={() => router.push('/login/?status=signup')}
              >
                会員登録
              </Button>
            </Stack>
          </>
        )}

        <ImageBox
          onClick={() => router.push('/search/notentered/')}
          sx={{ cursor: 'pointer' }}
        >
          <Typography fontSize={12} fontWeight={500} color="white">
            キャンプ場の
            <br />
            写真・情報募集中!
          </Typography>
          <Stack alignEnd space={0.5}>
            <Typography fontSize={12} fontWeight={700} color="white">
              残り
            </Typography>
            <Typography
              fontSize={34}
              fontWeight={900}
              lineHeight={1}
              color="white"
            >
              {num}
            </Typography>
            <Typography fontSize={12} fontWeight={700} color="white">
              施設
            </Typography>
          </Stack>
        </ImageBox>
      </Stack>
      <Stack between alignEnd>
        <Stack space={3}>
          <Button
            onClick={() =>
              window.open('https://www.instagram.com/iihi.camp/', '_blank')
            }
            component="label"
            sx={{ minWidth: 'unset', padding: 'unset' }}
          >
            <Image
              src="/assets/images/common/btn_insta.svg"
              width={40}
              height={40}
              alt="instagram"
            />
          </Button>
          <Button
            onClick={() => window.open('https://note.com/iihi_camp/', '_blank')}
            component="label"
            sx={{ minWidth: 'unset', padding: 'unset' }}
          >
            <Image
              src="/assets/images/common/btn_note.svg"
              width={40}
              height={40}
              alt=""
            />
          </Button>
        </Stack>
        <Stack column space={1.25}>
          <Typography
            onClick={() => router.push('/news')}
            fontSize={10}
            fontWeight={500}
            color="#818982"
            sx={{ cursor: 'pointer' }}
          >
            お知らせ
          </Typography>
          <Typography
            onClick={() => router.push('/faq')}
            fontSize={10}
            fontWeight={500}
            color="#818982"
            sx={{ cursor: 'pointer' }}
          >
            よくあるご質問
          </Typography>
          <Typography
            onClick={() => router.push('/contact/user')}
            fontSize={10}
            fontWeight={500}
            color="#818982"
            sx={{ cursor: 'pointer' }}
          >
            お問い合わせ
          </Typography>
          <Typography
            onClick={() => router.push('/terms')}
            fontSize={10}
            fontWeight={500}
            color="#818982"
            sx={{ cursor: 'pointer' }}
          >
            利用規約
          </Typography>
          {/* <Typography
            onClick={() => nextPage('/tokushoho')}
            fontSize={10}
            fontWeight={500}
            color="#818982"
            sx={{ cursor: 'pointer' }}
          >
            特定商取引法に基づく表示
          </Typography> */}
          <Typography
            onClick={() => router.push('/privacy')}
            fontSize={10}
            fontWeight={500}
            color="#818982"
            sx={{ cursor: 'pointer' }}
          >
            プライバシーポリシー
          </Typography>
          <Typography
            onClick={() => router.push('/guideline')}
            fontSize={10}
            fontWeight={500}
            color="#818982"
            sx={{ cursor: 'pointer' }}
          >
            iihiを楽しむためのガイド
          </Typography>
          {loginUser && (
            <Link href={'/login'}>
              <a
                onClick={() => {
                  Auth.signOut().then(() => {
                    router.push('/login');
                  });
                  close();
                }}
              >
                <Typography fontSize={10} fontWeight={500} color="#818982">
                  ログアウト
                </Typography>
              </a>
            </Link>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PCSuggest;
