import styled from '@emotion/styled';
import {
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
} from '@mui/material';

import theme from '@/styles/theme';

export const FormControlLabel = styled(MuiFormControlLabel)`
  align-items: flex-start;
  padding: ${theme.spacing(1)};
  width: 100%;
  border-radius: ${theme.spacing(1)};
  &.checked {
    height: 100%;
    background: #e2edf5;
  }
` as typeof MuiFormControlLabel;

export const Checkbox = styled(MuiCheckbox)`
  padding-right: ${theme.spacing(1)};
` as typeof MuiCheckbox;
