import { useContext, useEffect } from 'react';

import CommonDialog from '@/contents/Dialogs/CommonDialog';
import CommonSubDialog from '@/contents/Dialogs/CommonSubDialog';
import CommonThirdDialog from '@/contents/Dialogs/CommonThirdDialog';
import FavoriteDialog from '@/contents/Dialogs/FavoriteDialog';
import NotifyDialog from '@/contents/Dialogs/NotifyDIalog';
import SlideDialog from '@/contents/Dialogs/SlideDialog';
import { GlobalContext } from '@/contents/hooks/state';
import GlobalNavi from '@/layouts/components/GlobalNavi';

import { preventScroll } from '../hooks/preventScroll';

const DialogSwitcher = () => {
  const { dialogStatus } = useContext(GlobalContext);

  useEffect(() => {
    if (process.browser) {
      preventScroll(dialogStatus.open);
    }
  }, [dialogStatus.open]);

  if (dialogStatus.open) {
    switch (dialogStatus.type) {
      case 'COMMON':
        return <CommonDialog />;
      case 'COMMON_SUB':
        return <CommonSubDialog />;
      case 'COMMON_THIRD':
        return <CommonThirdDialog />;
      case 'GLOBAL_NAVI':
        return <GlobalNavi />;
      case 'FAVORITE':
        return <FavoriteDialog />;
      case 'CAMP_IMAGE_SLIDE':
        return <SlideDialog />;
      case 'NOTIFY':
        return <NotifyDialog />;
      default:
        return <></>;
    }
  }

  return <></>;
};

export default DialogSwitcher;
